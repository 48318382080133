@mixin border-radius($val) {
    -moz-border-radius: $val;
    -webkit-border-radius: $val;
    border-radius: $val;
}

@mixin border-radius-right($val) {
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin border-radius-left($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
}

@mixin border-radius-top($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
}

@mixin border-radius-bottom($val) {
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin gradient($deg, $color1, $color2) {
    background: -moz-linear-gradient($deg, $color1 0%, $color2 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, $color1), color-stop(100%, $color2)); /* safari4+,chrome */
    background: -webkit-linear-gradient($deg, $color1 0%, $color2 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient($deg, $color1 0%, $color2 100%); /* opera 11.10+ */
    background: -ms-linear-gradient($deg, $color1 0%, $color2 100%); /* ie10+ */
    background: linear-gradient($deg, $color1 0%, $color2 100%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 ); /* ie6-9 */
}

@mixin background-gradient-left2right($start-color, $end-color) {
    background-color: $start-color;
    background-image: -webkit-gradient(linear, left top, right top, from($start-color), to($end-color));
    background-image: -webkit-linear-gradient(to right, $start-color, $end-color);
    background-image:    -moz-linear-gradient(to right, $start-color, $end-color);
    background-image:     -ms-linear-gradient(to right, $start-color, $end-color);
    background-image:      -o-linear-gradient(to right, $start-color, $end-color);
    background-image:         linear-gradient(to right, $start-color, $end-color);
    filter:            progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}', gradientType='1');
}

@mixin background-gradient-right2left($start-color, $end-color) {
    background-color: $start-color;
    background-image: -webkit-gradient(linear, right top, left top, from($start-color), to($end-color));
    background-image: -webkit-linear-gradient(to left, $start-color, $end-color);
    background-image:    -moz-linear-gradient(to left, $start-color, $end-color);
    background-image:     -ms-linear-gradient(to left, $start-color, $end-color);
    background-image:      -o-linear-gradient(to left, $start-color, $end-color);
    background-image:         linear-gradient(to left, $start-color, $end-color);
    filter:            progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}', gradientType='1');
}

@mixin background-gradient-top2bottom($start-color, $end-color) {
    background-color: $start-color;
    background-image: -webkit-gradient(linear, left top, left bottom, from($start-color), to($end-color));
    background-image: -webkit-linear-gradient(to bottom, $start-color, $end-color);
    background-image:    -moz-linear-gradient(to bottom, $start-color, $end-color);
    background-image:     -ms-linear-gradient(to bottom, $start-color, $end-color);
    background-image:      -o-linear-gradient(to bottom, $start-color, $end-color);
    background-image:         linear-gradient(to bottom, $start-color, $end-color);
    filter:            progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}');
}

@mixin background-gradient-bottom2top($start-color, $end-color) {
    background-color: $end-color;
    background-image: -webkit-gradient(linear, left bottom, left top, from($start-color), to($end-color));
    background-image: -webkit-linear-gradient(to top, $start-color, $end-color);
    background-image:    -moz-linear-gradient(to top, $start-color, $end-color);
    background-image:     -ms-linear-gradient(to top, $start-color, $end-color);
    background-image:      -o-linear-gradient(to top, $start-color, $end-color);
    background-image:         linear-gradient(to top, $start-color, $end-color);
    filter:            progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}');
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin no-shadow() {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

@mixin shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}

@mixin multi-shadow($shadow...) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin translateX($val) {
    -webkit-transform: translateX($val);
    -moz-transform: translateX($val);
    -o-transform: translateX($val);
    -ms-transform: translateX($val);
    transform: translateX($val);
}

@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model;
    -moz-box-sizing: $box-model;
    box-sizing: $box-model;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

@mixin border-box-sizing() {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -o-transform: rotate($deg);
    -ms-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin scale($deg) {
    -webkit-transform: scale($deg);
    -moz-transform: scale($deg);
    -o-transform: scale($deg);
    -ms-transform: scale($deg);
    transform: scale($deg);
}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin flex() {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin flex-direction-row() {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin flex-direction-column() {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-justify-center() {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin flex-justify-between() {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin flex-align-center() {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@mixin flex-align-start() {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

@mixin flex-align-end() {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

@mixin flex-justify-start() {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@mixin flex-justify-end() {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

@mixin translateXForIE($val1, $val2) {
    -ms-transform: translateX($val1) translateX($val2);
}

