@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }
}

@keyframes rippleOn {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        transform: scale(13, 13);
    }
}

@-webkit-keyframes spin {
    from {
       -webkit-transform: rotate(0deg);
    }
    to {
       -webkit-transform: rotate(359deg);
    }
 }
 @keyframes spin {
    from {
       transform: rotate(0deg);
    }
    to {
       transform: rotate(359deg);
    }
 }

@keyframes rippleOff {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        transform: scale(13, 13);
    }
}

.fadeInDown {
    -webkit-animation: fadeInDown .5s; /* Safari 4.0 - 8.0 */
    animation: fadeInDown .5s;
}

.fadeOutUp {
    -webkit-animation: fadeOutUp $transitionDuration; /* Safari 4.0 - 8.0 */
    animation: fadeOutUp $transitionDuration;
}
