.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem {
    & > a.active-menuitem-routerlink{
        border-radius: 6px;
        .menuitem-text, i {
            color: var(--surface-700)!important;
        }
    }
} 
.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
    background-color: var(--surface-100)!important;
}
/* Add your customizations of layout here */
.layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-logo-wrapper .layout-topbar-logo img {
        height: auto;
        width: 45px;
}


body {
    background-color: white;
}

.layout-sidebar .sidebar-logo {
    margin-right: 10px;
    img {
        height: 30px;
        width: 30px;
    }
}

.layout-landing {
    .layout-main {
        margin-left: 0;
        .layout-topbar{
            width: 100%;
            @include transition(width $transitionDuration);
        }
    }
}

@media (max-width: $mobileBreakpoint) {
    .layout-wrapper {
        .layout-topbar {
            .layout-topbar-wrapper {
                @include flex-direction-column();
                .layout-topbar-right {
                    height: auto;
                    .layout-topbar-actions {
                        li {
                            ul {
                                top: 143px;
                            }
                        }
                        .profile-item {
                            display: initial;
                        }
                        .signout-item {
                            display: initial;
                        }
                    }
                }
            }
        }
    }
}



.layout-topbar-right.layout-topbar-right {
    .layout-topbar-actions.layout-topbar-actions {
        > li {
            > ul {
                left: initial;
                &:before {
                    left: 100px;
               }
            }
        }
    }
}
@media (min-width: $mobileBreakpoint) {
    .layout-wrapper {
        .layout-topbar {
            .layout-topbar-wrapper {
                .layout-topbar-left {
                    .login-button {
                        display: none;
                    }
                }
                .layout-topbar-right {
                    .layout-topbar-actions {
                        .signout-item {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $mobileBreakpoint) {
    .layout-wrapper {
        &.layout-topbar-mobile-active {
            .layout-topbar {
                .layout-topbar-wrapper {
                    .layout-topbar-left {
                        .layout-megamenu {
                            top: 145px;
                            height: calc(100% - 145px);
                        }
                    }

                   .layout-topbar-right {
                       display: block;
                   }
                }
            }

            .layout-rightpanel {
                top: 145px;
                height: calc(100% - 145px);
            }

            .layout-sidebar {
                top:145px;

                .layout-menu-container {
                    height: calc(100% - 145px);
                }
            }
        }
    }
}

.layout-topbar {
    background-color: #fff;
}

.layout-breadcrumb {
    background-color: rgba(0,0,0,0.02);
}

.layout-topbar {
    .layout-topbar-wrapper {
        .layout-topbar-right {
            .profile-item {
                > a {
                    @include flex-justify-center();
                }
                > ul {
                    > li {
                        &.profile-submenu-header {
                            @include flex-justify-end();
                        }
                    }
                }
            }
        }
    }
}