.layout-menu-blue {
    $menuBgColor:#354563;
    $rootMenuitemTextColor:#ffffff;
    $rootMenuitemIconColor:#ffffff;
    $rootMenuitemHoverBgColor:rgba(255, 255, 255, 0.24);
    $activeRootMenuitemBgColor:#f8c869;
    $activeRootMenuitemTextColor:#343a40;
    $activeRootMenuitemIconColor:#343a40;
    $submenuBgColor:rgba(255, 255, 255, 0.12);
    $horizontalSubmenuBgColor:#2e3440;
    $submenuItemTextColor:#ffffff;
    $submenuItemIconColor:#ffffff;
    $submenuItemHoverBgColor:rgba(0, 0, 0, 0.36);
    $activeSubmenuItemTextColor:#f8c869;
    $activeSubmenuItemIconColor:#f8c869;
    $appNameTextColor:#ffffff;
    $pinBgColor:#ffffff;
    $pinIconColor:#2e3440;

    @import '../_menu_theme';
}

