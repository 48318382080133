/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/demo/flags/flags.css";
@import "assets/demo/documentation.scss";

.truncate-text {
    white-space: nowrap;        /* Prevent text from wrapping to a new line */
    overflow: hidden;           /* Hide the overflowing text */
    text-overflow: ellipsis;    /* Show ellipsis for the overflowing text */
    width: 200px;               /* Set a fixed width or a max-width for the container */
}